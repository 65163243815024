import { useEffect, useState } from "react";
import CarDetails from "../components/cards/CarDetails";
import PreviewCard from "../components/cards/PreviewBookingCard";
import { UserData } from "../type/UserDetails";
import { clearStorage, getItem, setItem } from "../util/storageService";
import CardNames from "../util/CardNames";
import apiService from "../api/api";
import { navigateTo } from "../router/appRouter";
import i18n from "../i18n";
import FullLoader from "../components/common/Loader";
import handleApiError from "../util/ErrorService";
import Modal from "../components/modal";
import PromptModal from "../components/modal/child/PromptModal";
import { useTranslation } from "react-i18next";

const PreviewBooking = () => {
  const [booingStatus, setBookingStatus] = useState(false);
  const [confirm, setConfirmBook] = useState(true);
  const [loader, setLoader] = useState(false);
  const [confirmPrevData, setconfirmPrevData] = useState(false);
  const [reasonDta, setReasonData] = useState<any>();

  const [bookingdata, setBookingData] = useState<any>();
  const userDetails: UserData = getItem("userDetails");
  const summary = getItem("userSummary");
  const odoMeterReading = summary?.find(
    (item: { card_name: string }) => item.card_name === CardNames?.ODO_MILEAGE
  );
  const cancelId = getItem("cancelId");

  const { t } = useTranslation();

  useEffect(() => {
    const step = getItem("currentStep");
    const userData = getItem("userDetails");
    const userSummary = getItem("userSummary");
    if (userSummary?.length === 5) {
      setConfirmBook(false);
    } else {
      navigateTo(`/${i18n.language}/manage-service`);
    }
  }, []);

  useEffect(() => {
    const userDetail: any = getItem("userDetails");
    if (userDetail?.data?.data?.token) getReasons();
  }, []);

  const handleComponent = (nextcomponent: string) => {};
  const confirmBooking = () => {
    submitBooking();
    closeModal();
  };
  const confirmBookingPrompt = () => {
    setconfirmPrevData(true);
  };
  const getReasons = () => {
    apiService
      .get("/app/customer/service-cancellation-reasons")
      .then((response) => {
        const respdata = response?.data?.data;
        const optionData = respdata.map((respdata: any) => ({
          value: respdata._id,
          label: respdata.name,
        }));
        setReasonData(optionData);
      })
      .catch((error) => {
        // handleApiError(error);
      });
  };

  const backTo = () => {
    navigateTo(`/${i18n.language}/book-a-service`);
  };
  const downloadICSFile = async () => {
    apiService
      .get(`/service-booking/${bookingdata?.data?.data?._id}/calendar`)
      .then((response) => {
        setBookingStatus(true);
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "calendar.ics");
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      })
      .catch((error) => {
        setLoader(false);
        handleApiError(error);
      });
  };
  const submitBooking = () => {
    setLoader(true);
    if (cancelId) {
      const data = {
        cancellationReasonId: reasonDta[0]?.value,
        cancellationComment: "",
        customerId: userDetails?.data?.data?.userId,
      };

      apiService
        .post(`/app/customer/cancel-booking/${cancelId}`, data)
        .then((response) => {
          const cartData = getItem("cart");
          const payload = {
            cartId: cartData?._id,
            serviceBookingPaymentType: "pay_at_center",
          };
          return apiService.post("/app/cart/checkout", payload);
        })
        .then((response) => {
          setBookingData(response);
          setBookingStatus(true);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          handleApiError(error);
        });
    } else {
      const cartData = getItem("cart");
      const payload = {
        cartId: cartData?._id,
        serviceBookingPaymentType: "pay_at_center",
        source:"website"
      };

      apiService
        .post("/app/cart/checkout", payload)
        .then((response) => {
          setBookingData(response);
          setBookingStatus(true);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          handleApiError(error);
        });
    }
  };

  const backToHome = () => {
    navigateTo(`/${i18n.language}/`);
  };
  const viewOrManage = () => {
    const userData = getItem("userDetails");
    const searchBy = getItem("search_by");
    const searchedPlateNumber = getItem("plate_number_manage");
    const searchedVinNumber = getItem("vin_number_manage");
    const plateNumber =
      userData?.data?.data?.user?.customerVehicles?.licensePlate;
    const vin = userData?.data?.data?.user?.customerVehicles?.vin;
    clearStorage();
    if (searchBy == "plateNumber" || searchedPlateNumber)
      setItem("plate_number_manage", plateNumber);
    else if (searchBy == "vin" || searchedVinNumber)
      setItem("vin_number_manage", vin);
    navigateTo(`/${i18n.language}/manage-service`);
  };

  const closeModal = () => {
    setconfirmPrevData(false);
  };

  return (
    <>
      {confirmPrevData && (
        <Modal
          closeIcon={true}
          closeModal={closeModal}
          customClass={"max-w-[540px] md:!max-w-[540px]"}
          backButton={() => {}}
        >
          <PromptModal
            title={t("Confirm Booking!",i18n.language)}
            subTitle={t("Are you sure you want to proceed?",i18n.language)}
            cancelModal={closeModal}
            confirmModal={confirmBooking}
          />
        </Modal>
      )}
      {loader && (
        <FullLoader
          fullLoader={true}
          iconColor="#023f88"
          class="w-[100px] text-primary"
        />
      )}
      <div className="max-w-[830px] mx-auto relative mt-10 mb-[50px] px-4">
        <div className="w-full">
          <h2 className="font-medium text-[22px] text-black mb-7">
            {t("Preview Booking",i18n.language)}
          </h2>
          <div className="w-full">
            <PreviewCard
              bookingdata={bookingdata}
              bookingSubmited={booingStatus}
              cardActive={true}
              onSubmit={handleComponent}
            />
          </div>
          {!booingStatus && (
            <div className="masterBtn mt-8 flex items-center justify-start gap-4">
              <button
                className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md"
                onClick={() => backTo()}
              >
              {t("Back",i18n.language)}
              </button>
              <button
                className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                disabled={confirm}
                onClick={() => confirmBookingPrompt()}
              >
                {t("Confirm and Book",i18n.language)}
              </button>
            </div>
          )}
          {booingStatus && (
            <div className="masterBtn mt-8 flex items-center justify-start gap-4 flex-col md:flex-row">
              <button
                className="h-12 text-sm w-full md:w-40 disabled:bg-[#C6C8C9] bg-white border-primary border-2 cursor-pointer disabled:cursor-not-allowed text-primary rounded-md"
                onClick={() => backToHome()}
              >
                {t("Go Home",i18n.language)}
              </button>
              <button
                className="h-12 text-sm w-full md:w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                onClick={() => downloadICSFile()}
              >
                {t("Add to Calendar",i18n.language)}
              </button>
              <button
                className="h-12 text-sm w-full md:w-60 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                onClick={() => viewOrManage()}
              >
                {t("View/Manage Booking",i18n.language)}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PreviewBooking;
