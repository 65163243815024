import { Key, useEffect, useState } from "react";
import Icon from "../icons/Icons";
import CardLayout from "../common/Card";
import CardNames from "../../util/CardNames";
import SelectKilloMeeter from "../misc/SelectKilloMeeter";
import AdditionalService from "../misc/AdditionalService";
import InspectionCheckList from "../misc/InspectionCheckList";
import AddNote from "../misc/Addnote";
import Modal from "../modal";
import NotesModal from "../modal/child/NotesModal";
import { getItem, setItem } from "../../util/storageService";
import apiService from "../../api/api";
import { Item, ServiceObject } from "../../type/Service";
import { CartRequest } from "../../type/Cart";
import { UserData } from "../../type/UserDetails";
import { updateOrAddCardData } from "../../util/ManageSummary";
import { CardData } from "../../type/Storage";
import handleApiError from "../../util/ErrorService";
import { useTranslation } from "react-i18next";
import FullLoader from "../common/Loader";

interface customProps {
  cardActive: boolean;
  onSubmit: (nextcomponent: string) => void;
  edit?: string;
  editPrompt(card: any): void;
}

const RequestedService = (props: customProps) => {
  const [nextcomponent, setSetcomponent] = useState(CardNames?.DATE_TIME);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>();
  const [products, setProducts] = useState<any>([]);
  const [btnDisable, setBtn] = useState(true);
  const [selItems, setSelItem] = useState<any>();
  const [note, commonNote] = useState<string>("");
  const [items, setItems] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>();
  const { t, i18n } = useTranslation();

  const currentSteplocal = getItem("currentStep");
  const [currentStep, setCurrentStep] = useState(currentSteplocal);
  const [infoModal, setInfoModal] = useState<boolean>(false);

  const cardData = getItem("userSummary");
  const card = cardData?.find(
    (item: { card_name: any }) =>
      item.card_name === CardNames?.REQUESTED_SERVICE
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Initially set to the first product

  const toggleTab = (index: any) => {
    setActiveTabIndex(index === activeTabIndex ? -1 : index); // Close the tab if it's already active
  };
  useEffect(() => {
    if (props?.cardActive) {
      setSubmit(false);
    }
    const currentStep = getItem("currentStep");
    if (currentStep === CardNames?.REQUESTED_SERVICE) {
      getProducts();
    }
    const cardData = getItem("userSummary");
    const cardDefault = cardData?.find(
      (item: { card_name: any }) =>
        item.card_name === CardNames?.REQUESTED_SERVICE
    );
    const allServices = products.reduce((acc: any, item: any) => {
      return acc.concat(item.services);
    }, []);

    const matchingItems = allServices.filter((item1: any) =>
      cardDefault?.data?.some((item2: any) => item1.name === item2.title)
    );
    if (props?.cardActive && props?.edit === CardNames.REQUESTED_SERVICE) {
      setItems(matchingItems);
    }
  }, [props?.cardActive, props?.edit]);

  useEffect(() => {
    const summary = getItem("userSummary");
    const card = summary?.find(
      (item: { card_name: any }) =>
        item.card_name === CardNames?.REQUESTED_SERVICE
    );

    if (card) {
      setSummaryData(card);
      if (props.edit === "") setSubmit(true);
    }
  }, [isSubmit]);
  const groupAndSortServices = (services: any[]) => {
    const grouped = services.reduce((acc, service) => {
      const { code, name } = service.serviceTypeDetails;
      if (!acc[code]) {
        acc[code] = {
          name,
          services: [],
        };
      }
      acc[code].services.push(service);
      return acc;
    }, {});

    // Sort the keys and create a sorted array of grouped services
    const sortedKeys = Object.keys(grouped).sort();
    const sortedGroupedServices = sortedKeys.map((key) => ({
      code: key,
      name: grouped[key].name,
      services: grouped[key].services,
    }));

    return sortedGroupedServices;
  };
  const groupAndSortServicesWithCategories = (services: any[]) => {
    const grouped = services.reduce((acc, service) => {
      const { code, name, translations } = service.categoryDetails;
      if (!acc[code]) {
        acc[code] = {
          name,
          services: [],
          translations,
        };
      }
      acc[code].services.push(service);
      return acc;
    }, {});

    // Sort the keys and create a sorted array of grouped services
    const sortedKeys = Object.keys(grouped).sort();
    const sortedGroupedServices = sortedKeys.map((key) => ({
      code: key,
      name: grouped[key].name,
      arabicName: grouped[key].translations?.ar,
      services: grouped[key].services,
    }));
    console.log("sortedGroupedServices", sortedGroupedServices);

    return sortedGroupedServices;
  };

  const getProducts = () => {
    const summary = getItem("userSummary");
    setLoader(true);
    const odoVal = summary?.find(
      (item: { card_name: any }) => item.card_name === CardNames?.ODO_MILEAGE
    );
    const dealer = summary?.find(
      (item: { card_name: any }) =>
        item.card_name === CardNames?.DEALER_LOCATION
    );
    const vDetail = summary?.find(
      (item: { card_name: any }) => item.card_name === CardNames?.PLATE_NUMBER
    );

    apiService
      .get(
        `/app/cart/service-products?odometerReadingValue=${odoVal?.temp?.odoVal}&vehicleId=${vDetail?.temp?.vId}&serviceCenterId=${dealer?.temp?.selectedLocation}`
      )
      .then((response) => {
        const sorted = groupAndSortServicesWithCategories(response?.data?.data);
        setProducts(sorted);
        setLoader(false);
      })
      .catch((error) => {
        handleApiError(error);
        setLoader(false);
      });
  };

  const submitData = () => {
    manageCardData();
    saveCart();
    setItem("currentStep", CardNames?.DATE_TIME);
    if (props.edit !== "") {
      const summary = getItem("userSummary");

      const cardNamesToRemove = [CardNames?.DATE_TIME];
      const filteredData = summary.filter(
        (item: { card_name: string }) =>
          !cardNamesToRemove.includes(item.card_name)
      );
      if (filteredData) {
        setItem("userSummary", filteredData);
      }
    }
  };

  const addNotesData = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const noteValue = (val: string) => {
    if (val.length > 0) {
      closeModal();
    }
  };

  const manageCardData = () => {
    const data: CardData = {
      card_name: CardNames?.REQUESTED_SERVICE,
      title: "Requested Service",
      temp: {
        note: note,
        items: items,
      },
      data: [],
    };

    items.map((item: Item) => {
      data.data.push({
        title: item?.name || "",
        title_arabic: item?.translations?.ar,
        sub_title: "",
      });
    });

    updateOrAddCardData("", data, "userSummary");
  };

  const saveCart = () => {
    const userDetails: UserData = getItem("userDetails");
    const summary = getItem("userSummary");
    const odoMeterReading = summary?.find(
      (item: { card_name: string }) => item.card_name === CardNames?.ODO_MILEAGE
    );
    const serviceLocation = summary?.find(
      (item: { card_name: string }) =>
        item.card_name === CardNames?.DEALER_LOCATION
    );
    const transformedItems = items.map((item) => ({
      itemId: item._id,
      itemType: "mileage",
      quantity: 1,
    }));

    const payload: any = {
      userId: userDetails?.data?.data?.userId,
      vehicleId:
        userDetails?.data?.data?.user?.customerVehicles?.customerVehicleId,
      currency: {
        code: "SAR",
        name: "Saudi Riyal",
      },
      items: transformedItems,
      instance: "aljazirah",
      note: note,
      serviceType: "routine",
      serviceAt: "",
      serviceDate: "",
      pickupLocation: "",
      contactNumber: {
        countryCode: userDetails?.data?.data?.user?.countryCode,
        number: userDetails?.data?.data?.user?.primaryPhone?.slice(3),
      },
      contactPersonName: userDetails?.data?.data?.user?.fullName,
      contactEmail: userDetails?.data?.data?.user?.emails[0]?.email,
      dropLocation: "",
      serviceAdvisor: "",
      serviceCenter: serviceLocation?.temp?.selectedLocation,
      odoMeterReading: odoMeterReading?.temp?.odoVal,
    };
    const cart = getItem("cart");
    if (cart) {
      setLoader(true);
      const deleteURl =
        process.env.REACT_APP_AUTOMOTIVE_API_DOMAIN +
        "/api/cart/" +
        cart?._id +
        "/item";
      apiService
        .delete(deleteURl)
        .then((response) => {
          if (response && response?.data) {
          }
          setLoader(false);
        })
        .catch((error) => {
          handleApiError(error);
          setLoader(false);
        });
      apiService
        .patch(
          `${process.env.REACT_APP_AUTOMOTIVE_API_DOMAIN}/api/cart/${cart?._id}`,
          payload
        )
        .then((response) => {
          // setItem("cart", response?.data?.data);
          setSubmit(true);
          props.onSubmit(nextcomponent);
          setLoader(false);
        })
        .catch((error) => {
          handleApiError(error);
          setLoader(false);
        });
    }
    setLoader(true);
    apiService
      .post(
        `${process.env.REACT_APP_AUTOMOTIVE_API_DOMAIN}/api/cart`,
        payload,
        "cart"
      )
      .then((response) => {
        setItem("cart", response?.data?.data);
        setSubmit(true);
        props.onSubmit(nextcomponent);
        setLoader(false);
      })
      .catch((error) => {
        handleApiError(error);
        setLoader(false);
      });
  };

  const setItemsData = (item: any, type: string) => {
    if (items.length >= 4) {
      const itemExists = items.some(
        (existingItem) => existingItem._id === item._id
      );
      if (!itemExists) {
        setInfoModal(true);
        return;
      }
    }
    setSelItem(item);
    if (type === "checkbox") {
      if (item) {
        setItems((prevItems) => {
          const itemExists = prevItems.some(
            (existingItem) => existingItem._id === item._id
          );
          if (itemExists) {
            return prevItems.filter(
              (existingItem) => existingItem._id !== item._id
            );
          } else {
            return [...prevItems, item];
          }
        });
      }
    } else {
      setItems((prevItems) => {
        // const itemExists = prevItems.some(
        //   (existingItem) => existingItem._id === item._id
        // );
        const routineServices = products?.find(
          (item: any) => item?.code === "routine"
        )?.services;
        const matchingItems = routineServices.filter((item1: any) =>
          items?.some((item2: any) => item1.name === item2.name)
        );

        // if (itemExists) {
        //   return prevItems.filter(
        //     (existingItem) => existingItem._id !== item._id
        //   )
        // } else
        if (matchingItems?.length > 0) {
          return [
            ...items.filter(
              (existingItem) => existingItem?._id !== matchingItems?.[0]?._id
            ),
            item,
          ];
        } else {
          return [...items, item];
        }
      });
    }
  };

  useEffect(() => {
    if (items?.length > 0) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [selItems]);

  const setCommonNote = (text: string) => {
    commonNote(text);
  };

  return (
    <>
      {modal && (
        <Modal closeIcon={true} closeModal={closeModal} backButton={() => { }}>
          <NotesModal submit={noteValue} />
        </Modal>
      )}
      {infoModal && (
        <Modal
          closeIcon={true}
          closeModal={() => setInfoModal(false)}
          backButton={() => { }}
        >
          <div className="pe-8">
            <p className="mb-3">{t("serviceInfo1", i18n.language)}</p>
            <p>{t("serviceInfo2", i18n.language)}</p>
          </div>
        </Modal>
      )}
      <CardLayout
        bg={`${isSubmit ? " border-[#E9E9E9] border" : ""} ${props.cardActive ? "bg-[#FAFAFA] border-0" : ""
          }`}
      >
        <div className="relative">
          {loader && (
            <FullLoader
              fullLoader={false}
              iconColor="#023f88"
              class="w-[100px] text-primary"
            />
          )}
          <div className="cardHeader flex items-center">
            <Icon name={isSubmit ? "activeStep" : "inactiveStep"} />
            <div className="flex items-start flex-col ml-[15px]">
              <label className="font-medium text-lg text-black block rtl:mr-2 rtlmr-2">
                {t("Requested Service", i18n.language)}
              </label>
              {isSubmit && (
                <>
                  <span className="font-medium text-sm block">
                    {i18n.language === 'ar' ? summaryData?.data[0]?.title_arabic : summaryData?.data[0]?.title}
                    {summaryData?.data?.length > 1 && (
                      <span> & {t("other")} {summaryData?.data?.length - 1} {t("selected")}</span>
                    )}
                  </span>
                  <span
                    onClick={() => {
                      props?.editPrompt(card);
                    }}
                    className={`${currentStep === CardNames?.REQUESTED_SERVICE
                      ? "hidden"
                      : ""
                      } absolute rtl:left-0 ltr:right-0 cursor-pointer top-5`}
                  >
                    <Icon name="arrowLarge" />
                  </span>
                </>
              )}
            </div>
          </div>
          {!isSubmit && props?.cardActive && (
            <div className="cardBody mt-[28px] md:ml-[37px]">
              <div className="carDetails">
                {products &&
                  products
                    ?.filter((product: null) => product !== null)
                    .map((product: any, index: Key | null | undefined) => (
                      <div key={index}>
                        {/* <SelectKilloMeeter activeTab={true} /> */}
                        {/* <AdditionalService activeTab={true} /> */}
                        <InspectionCheckList
                          selectedData={setItemsData}
                          activeTab={index === activeTabIndex}
                          toggleTab={() => toggleTab(index)}
                          product={product}
                          addNotes={addNotesData}
                          selectedOptions={items}
                        />
                      </div>
                    ))}
                <AddNote
                  getNoteData={(text) => setCommonNote(text)}
                  activeTab={true}
                />
              </div>
              <div className="btnArea mt-1">
                <button
                  className="h-12 text-sm w-40 disabled:bg-[#C6C8C9] bg-secondary cursor-pointer disabled:cursor-not-allowed text-white rounded-md"
                  onClick={() => submitData()}
                  disabled={btnDisable}
                >
                  {t("Continue", i18n.language)}
                </button>
              </div>
            </div>
          )}
        </div>
      </CardLayout>
    </>
  );
};

export default RequestedService;
